import {
  faArchive,
  faDog,
  faPhoneAlt,
  faSackDollar,
  faTruck,
  faUserCircle,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Disclosure } from '@headlessui/react'
import { Auth } from 'aws-amplify'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Navbar from 'components/ui/navbar/Navbar'

const subNavigation = [
  { name: 'Spoof', href: '/admin', icon: faUserCircle, current: true, isAdmin: true, isStaff: true },
  { name: 'Dog Names', href: '/admin/dognames', icon: faDog, current: false, isAdmin: true, isStaff: true },
  { name: 'Xero', href: '/admin/xero', icon: faSackDollar, current: false, isAdmin: true, isStaff: false },
  { name: 'Apicbase', href: '/admin/apicbase', icon: faWarehouse, current: false, isAdmin: true, isStaff: false },
  { name: 'Shipping', href: '/admin/shipping', icon: faTruck, current: false, isAdmin: true, isStaff: true },
  { name: 'PPD', href: '/admin/ppd', icon: faArchive, current: false, isAdmin: true, isStaff: true },
  {
    name: 'Customer Delight',
    href: '/admin/customerdelight',
    icon: faPhoneAlt,
    current: false,
    isAdmin: true,
    isStaff: true
  }

  // { name: 'Cache', href: '/admin/cache', icon: faCog, current: false, isAdmin: true, isStaff: false }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const AdminLayout: React.FC<any> = ({ children }) => {
  const [groups, setGroups] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState('/admin')

  useEffect(() => {
    const checkAuth = async () => {
      const {
        idToken: { payload = {} } = {}
      } = await Auth.currentSession()
      setGroups(payload['cognito:groups'] || [])
    }

    checkAuth().catch(err => console.error(JSON.stringify(err, null, 2)))
  }, [])

  return (
    <>
      <Helmet>
        {/* Plausible Analytics script */}
        {/* <script defer data-api="/api/event" data-domain="differentdog.com" src="/js/script.js"></script> */}
      </Helmet>
      <div>
        <Navbar />
        <Disclosure as="div" className="relative overflow-hidden bg-sky-700 pb-32">
          {({ open }) => (
            <>
              <div
                aria-hidden="true"
                className={classNames(
                  open ? 'bottom-0' : 'inset-y-0',

                  'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
                )}
              >
                <div className="absolute inset-0 flex">
                  <div className="h-full w-1/2" style={{ backgroundColor: '#0a527b' }} />

                  <div className="h-full w-1/2" style={{ backgroundColor: '#065d8c' }} />
                </div>

                <div className="relative flex justify-center">
                  <svg
                    className="flex-shrink-0"
                    width={1750}
                    height={308}
                    viewBox="0 0 1750 308"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#0369a1" />

                    <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#065d8c" />

                    <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0a527b" />

                    <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#0a4f76" />
                  </svg>
                </div>
              </div>

              <header className="relative py-5">
                {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-white">Admin Dashboard</h1>
              </div> */}
              </header>
            </>
          )}
        </Disclosure>

        <main className="relative -mt-32">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="py-6 lg:col-span-3">
                  <nav className="space-y-1">
                    {subNavigation.map(
                      item =>
                        ((groups.includes('admin') && item.isAdmin) || (groups.includes('staff') && item.isStaff)) && (
                          <Link
                            data-testid={item.name.replace(' ', '').toLowerCase()}
                            key={item.name}
                            to={item.href}
                            onClick={() => setActiveTab(item.href)}
                            className={classNames(
                              activeTab === item.href
                                ? 'border-teal-500 bg-teal-50 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                                : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                              'group flex items-center border-l-4 px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={activeTab === item.href ? 'page' : undefined}
                          >
                            <FontAwesomeIcon
                              icon={item.icon}
                              className={classNames(
                                activeTab === item.href
                                  ? 'text-teal-500 group-hover:text-teal-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                '-ml-1 mr-3 h-6 w-6 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />

                            <span className="truncate">{item.name}</span>
                          </Link>
                        )
                    )}
                  </nav>
                </aside>

                <div className="p-10" style={{ width: '400px' }}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default AdminLayout
